.wrapper {
  margin-bottom: 40px;
}

.label {
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  letter-spacing: 0;
  margin-bottom: 0;
}
